@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bebas Neue';
  font-display: block;
  src: url('/static/fonts/bebasneue_bold.woff') format('woff'),
    url('/static/fonts/bebasneue_bold.ttf') format('truetype'),
    url('/static/fonts/bebasneue_bold.eot') format('eot');
  font-weight: normal;
  font-style: normal;
}

body {
  background: black;
}

html,
#__next,
body {
  height: 100%;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

@layer base {
  h1,
  h2,
  h4,
  h5 {
    @apply font-serif;
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }

  video {
    width: 100%;
    max-width: calc(100vw * 16 / 9) !important;
    max-height: calc(100vh - 80px) !important;
  }

  .skeleton {
    @apply relative overflow-hidden animate-pulse bg-[#323232];
  }

  #ppv-card li {
    @apply relative pl-6 mb-2 leading-normal;
  }

  #ppv-card li:last-of-type {
    @apply mb-0;
  }

  #ppv-card li::before {
    @apply absolute top-[5px] left-0 w-[13px] h-[10px];
    content: '';
    background: url(/static/images/check-red.svg) no-repeat;
    background-size: 13px 10px;
  }

  /* VideoPlayer styles */
  .vjs-big-play-button {
    position: absolute;
    box-sizing: border-box;
    font-size: 46px;
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    line-height: 1;
    border: 2px solid #fff;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .vjs-big-play-button:hover,
  .vjs-big-play-button {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .vjs-icon-placeholder {
    width: 16px;
    height: 16px;
  }

  .video-js *,
  .video-js video,
  .video-js video {
    outline: none;
  }
  .video-js video {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 10;
  }

  .video-js .vjs-control-bar {
    z-index: 10;
  }
  /*
  .vjs-icon-placeholder,
  .vjs-chromecast-button .vjs-icon-placeholder,
  .vjs-airplay-button .vjs-icon-placeholder {
     width: 16px !important;
     height: 16px !important;
  }
  */

  media-airplay-button[media-airplay-unavailable] {
    display: none;
  }

  /* no-bg-scrollbar classes */
  html.no-bg-scrollbar {
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }

  html .no-bg-scrollbar:active::-webkit-scrollbar-thumb,
  html .no-bg-scrollbar:focus-within::-webkit-scrollbar-thumb,
  html .no-bg-scrollbar:focus::-webkit-scrollbar-thumb,
  html .no-bg-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 4px solid transparent;
    background-clip: content-box;
    border-radius: 10px;
  }

  html .no-bg-scrollbar::-webkit-scrollbar,
  html .no-bg-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

/* swiper slider styles */

.swiper,
.swiper-wrapper {
  @apply !overflow-visible;
}

.swiper-slide {
  @apply !w-auto !h-auto;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  @apply font-bold text-white;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  @apply transition-opacity ease-in opacity-100;
}

.swiper-button-disabled {
  @apply !invisible;
}

.swiper-button-prev,
.swiper-button-next {
  @apply !hidden md:!flex !w-[150px] !h-[250px] sm:!h-[110%] !m-0 !-top-[6px] opacity-0 !z-50;
}

.swiper-button-next {
  @apply !-right-10 bg-gradient-to-r from-transparent to-black;
}

.swiper-button-next:after {
  @apply relative !right-4 -top-[30px];
}

.swiper-button-prev {
  @apply !-left-10 bg-gradient-to-l from-transparent to-black;
}

.swiper-button-prev:after {
  @apply relative !-left-4 -top-[30px];
}

/* banner ads */

#banner-ad .swiper-slide {
  @apply !w-full !h-auto;
}

/* mux player styles */

mux-player {
  aspect-ratio: 16 / 9;
}

.bstv-player {
  @apply justify-center w-full h-full align-middle;
}

.bstv-player video {
  @apply max-w-full max-h-full w-fit;
}

.bstv-media {
  @apply h-full flex justify-center align-middle w-[100vw];
}

/* modal body scrollbar styes */

/* Firefox */
#modal-body {
  scrollbar-width: thin;
  scrollbar-color: transparent;
  scroll-margin-top: 20px;
  scroll-margin-bottom: 20px;
}

/* Chrome, Edge, and Safari */
#modal-body::-webkit-scrollbar {
  width: 4px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}

#modal-body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#modal-body::-webkit-scrollbar-thumb {
  background-color: #7b7b7b;
  border-radius: 4px;
  border: 4px solid transparent;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Gradients */

.gradient-1 {
  background: linear-gradient(to top, #000000 0%, transparent 30%);
}

.gradient-2 {
  background: linear-gradient(to top, #000000 0%, transparent 30%),
    linear-gradient(to right, #000000 0%, transparent 50%);
}

.gradient-3 {
  background: linear-gradient(360deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
}

.gradient-4 {
  background: linear-gradient(90deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
}

.gradient-5 {
  background: linear-gradient(273.32deg, #121212 13.57%, #1c1c1c 51.36%, #121212 88.45%);
  border-image: linear-gradient(270deg, #121212 0%, #b73838 33.65%, #fff8b7 63.83%, #121212 95%)
    0.5%;
}

.gradient-6 {
  background: linear-gradient(107.65deg, #1c1c1c 0.77%, #121212 100%);
}

.gradient-7 {
  background: linear-gradient(
    0deg,
    #121212 0%,
    rgba(18, 18, 18, 0.9) 12.5%,
    rgba(18, 18, 18, 0) 30.21%
  );
}

.gradient-8 {
  background: linear-gradient(
    90deg,
    #121212 0%,
    rgba(18, 18, 18, 0.9) 12.5%,
    rgba(18, 18, 18, 0) 30.21%
  );
}

.mask-image-1 {
  -webkit-mask-image: linear-gradient(180deg, #121212 70%, rgba(18, 18, 18, 0) 100%);
}

.mask-image-2 {
  -webkit-mask-image: linear-gradient(180deg, #121212 85%, rgba(18, 18, 18, 0) 100%);
}

.mask-image-3 {
  -webkit-mask-image: linear-gradient(-90deg, #121212 50%, rgba(18, 18, 18, 0) 100%);
}
